<template>
  <v-content color="primary">
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ t(text) }}
      <v-btn flat @click="snackbar = false">
        {{ t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-container fluid id="main-container" grid-list-md>
      <v-layout row wrap justify-center class="d-inline-block w-100 mb-5">
        <v-card>
          <v-card-title>
            <span class="menu-icon bigger material-symbols-outlined">
              price_change
            </span>
            <span class="table-title">{{ t('Package cost') }}</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" :label="t('Search')" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :total-items="10" :headers="headers" :items="packages" :rows-per-page-text="t('Rows per page')"
            class="elevation-1" :loading="loading" :search="search" :pagination.sync="pagination" hide-actions>
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                <template v-if="!packageHeaderEdit[props.item.pk]">
                  {{ props.item.header }}
                </template>
                <v-text-field v-else v-model="packageNewHeader[props.item.pk]">
                </v-text-field>
              </td>
              <td class="text-xs-left">{{ props.item.age_limit }}</td>
              <!-- <td class="text-xs-left">{{ props.item.tariff + $store.getters.currency}}</td> -->
              <td class="text-xs-left">
                <v-flex v-if="!packageHeaderEdit[props.item.pk]">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="info" ripple icon small dark :to="`/packages/${props.item.pk}/details/`">
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="success" ripple icon small dark
                        @click="$set(packageHeaderEdit, props.item.pk, true)">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Edit') }}</span>
                  </v-tooltip>
                </v-flex>
                <template v-else>
                  <v-progress-circular v-if="packageHeaderSaving[props.item.pk]" indeterminate
                    color="primary"></v-progress-circular>
                  <v-flex v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="success" ripple icon small dark @click="savePackageHeader(props.item)">
                          <v-icon small>done</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ t('Save') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" ripple icon small dark
                          @click="packageNewHeader[props.item.pk] = ''; packageHeaderEdit[props.item.pk] = false;">
                          <v-icon small>close</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ t('Edit') }}</span>
                    </v-tooltip>
                  </v-flex>
                </template>
              </td>
            </template>
            <template slot="pageText" slot-scope="item">
              <!--                            {{t('Elements')}} {{item.pageStart}} - {{item.pageStop}},-->
              {{ t('total') }}: {{ item.itemsLength }}
            </template>
            <template slot="no-data">
              <v-subheader>{{ t('No data available') }}</v-subheader>
            </template>
          </v-data-table>
          <div
            style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination v-model="pagination.current" :length="pagination.total" :total-visible="5"></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
            </v-select>
          </div>
        </v-card>

      </v-layout>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-fab-transition v-if="$store.getters.isAdmin || $store.getters.isOwner">
            <v-btn color="primary" dark fixed bottom right icon large v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline">{{ t('Package') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>

                  <v-flex xs12 sm12>
                    <v-text-field :label="t('Header') + '*'" v-model="header" :rules="[rules.required,]">
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>{{ t('*indicates required field') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn color="primary" text @click="dialog = false">{{ t('Close') }}</v-btn>
            <v-btn color="primary" text @click="submitPackageForm">{{ t('Save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';

export default {
  name: "Packages",
  data() {
    return {
      pagination: {
        current: 1,
        total: 20,
        sortBy: undefined,
        descending: undefined,
        totalItems: 10
      },
      page_size: 10,
      next: null,
      previous: null,
      count: 0,
      headers: [
        { text: this.$translate.locale['Header'], value: 'header' },
        { text: this.$translate.locale['Age limit'], value: 'age_limit' },
        { text: this.$translate.locale['Action'], value: 'action', sortable: false },
      ],
      packages: [],
      packageHeaderEdit: {},
      packageHeaderSaving: {},
      packageNewHeader: {},
      loading: true,
      snackbar: false,
      dialog: false,
      search: "",
      text: 'Oops... Something went wrong',
      timeout: 5000,
      header: "",
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        counter: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
        number: value => {
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
      },
      getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),
    }
  },
  methods: {
    savePackageHeader(item) {
      const newHeader = this.packageNewHeader[item.pk];
      this.$set(this.packageHeaderSaving, item.pk, true);

      const putBody = {
        header: newHeader,
      };

      axios.put(`/api/packages/edit/header/${item.pk}/`, putBody)
        .then((response) => {
          if (response.status === 200) {
            item.header = newHeader;
            this.packageNewHeader[item.pk] = '';
            this.$set(this.packageHeaderEdit, item.pk, false);
            this.text = "Header changed!";
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = "Failed to set new header";
          this.snackbar = true;
        }).finally(() => {
          this.$set(this.packageHeaderSaving, item.pk, false);
        });
    },
    getData(resetPage=false) {
      let url = `/api/packages/`;

      const params = {
        page: this.pagination.current,
        page_size: this.page_size,
        sort_by: this.pagination.descending ? `-${this.pagination.sortBy}` : this.pagination.sortBy,
      };

      if (this.search) {
        params.search = this.search;

        if (this.pagination.current !== 1 && resetPage) {
          this.pagination.current = 1;
          // return, because changing `this.pagination.current`
          // will trigger getData() once more
          return;
        }
      }

      axios.get(url, {params})
        .then((response) => {
          if (response.status === 200) {
            this.packages = response.data.results;
            this.next = response.data.next;
            this.previous = response.data.previous;
            this.count = response.data.count;
            this.pagination.total = response.data.total_pages;
            this.pagination.totalItems = response.data.count;
            this.loading = false;
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
    submitPackageForm() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true
      } else {
        const data = {
          crossdomain: true,
          header: this.header
        };
        axios.post(`/api/packages/create/`, data)
          .then((response) => {
            if (response.status === 201) {
              this.submitted = true;
              this.dialog = false;
              this.getData();
              this.header = '';
              this.text = "Package created!";
              this.snackbar = true;
            }
          }).catch((error) => {
            this.text = "Connection error";
            this.snackbar = true;
          });
      }

    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    'pagination.current': function() { this.getDataDebounced(); },
    'page_size': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    'pagination.sortBy': function() { this.getDataDebounced(); },
    'pagination.descending': function() { this.getDataDebounced(); },
    'search': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
  }
}
</script>

<style scoped></style>
